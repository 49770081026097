import React from 'react';

import styled from 'styled-components';

import MountSinaiLogo from '../assets/img/mount_sinai_logo.png'
import AlbanyLogo from '../assets/img/albany_logo_2.png'
import MVPLogo from '../assets/img/mvp_logo.png'
import JPMLogo from '../assets/img/JP-Morgan-Chase-Logo.png'
import GoogleLogo from '../assets/img/Google-Logo.png'
import ExperienceItem from './ExperienceComponent/ExperienceItem';

const Container = styled.div`
overflow:hidden;
h1 {
    text-align: center;
}
hr {
    margin-bottom: 55px;
}
`

const experienceData = [
    {
        company: "Google",
        from: "August 2022",
        to: "Present",
        title: "Software Engineer",
        logo: GoogleLogo,
        bullets: ["Leading the development of a Gen AI-powered concierge service for billing support, overseeing the project from initial design to global launch for English-speaking customers, achieving a 30% increase in case deflection over the previous solution.",
            "Integrated advanced LLM capabilities into our concierge experience including Vertex function calling to enable real-time data through API access, google search based grounding for RAG, user signals retrieval, and an intent based multi-agent architecture.",
            "Oversaw safety initiatives for AI driven applications. Pioneered pre-processing filters to block PII sent by users, post-processing filters to remove harmful content generated by LLMs, and workflow policy checks, screening 10,000+ daily conversations.",
            "Established and independently manage a data pipeline of LLM interaction data including 6 tables piped into a multitude of data marts (some pseudonymized) utilized by 5+ teams and 30+ users for research, reporting, and analytics."],
        translate: [300, 0]
    },
    {
        company: "JPMorgan Chase",
        from: "October 2021",
        to: "July 2022",
        title: "Software Engineer",
        logo: JPMLogo,
        bullets: ["Refining production level code as a backend Java engineer for 20+ RESTful Spring-based microservices, including general improvements, new features, bug fixes, and integration with new services.",
            "Contributing to high-level discussions on improving authentication services which power consumer banking applications used by 50+ million users.",
            "Validating code-base changes by writing 10+ tests per sprint using JUnit (TDD) and Cucumber tests (BDD).",
            "Managed the deployment process by launching pools, creating global load balancers, and supervising the production deployment of microservices which are called 100+ million times per month for a variety of authentication processes."],
        translate: [-300, 0]
    },
    {
        company: "Mount Sinai Health System",
        from: "August 2020",
        to: "October 2021",
        title: "Research Programmer Analyst",
        logo: MountSinaiLogo,
        bullets: ["Directed and innovated an early-stage application as the leading developer and full stack project manager of a proprietary medical research e-consent platform supporting 70+ research projects, 250+ researchers, and 1000’s of patients.",
            "Strengthened the platform by architecting new features, developing new pipelines, fixing bugs, streamlining the continuous delivery of updates, testing functionality, managing deployment, and maintaining upkeep of 3 servers 24/7).",
            "Spearheaded updates including batch importing patients, Docusign integration, patient management dashboard for researchers, and multi-language support. These updates reduced procedure times by over 200% for 1000's of users."],
        translate: [300, 0]
    },
    {
        company: "UAlbany Data Mining Lab",
        from: "January 2020",
        to: "May 2020",
        title: "Research Project Software Engineer",
        logo: AlbanyLogo,
        bullets: ["Pioneered an independent full stack development project to build a user-friendly activity monitor for a location based mobile app to increase productivity through data visualization on a map with 100+ possible combinations for data analysis.",
            "Designed a REST API and login server using a MySQL database and the Express Node.js framework with 5+ routes in each.",
            "Constructed the activity monitor website featuring user authentication, user roles, an administrator panel, data analytics, and an interactive map generated with 7 user inputted parameters, built with Angular 8 and the Google Maps API."],
        translate: [-300, 0]
    },
    {
        company: "MVP Health Care",
        from: "June 2019",
        to: "August 2019",
        title: "Software Engineer Intern",
        logo: MVPLogo,
        bullets: ["Engineered 15 interactive SSRS reports for the operations department in an Agile Scrum development cycle, exceeding the department’s goal by 10+ reports for the summer.",
            "Produced 100+ queries, functions, stored procedures, and tables to develop new reports and improve existing reports.",
            "Reviewed, tested, and debugged new code and over 10 projects of legacy code.",
            "Led presentations for executive team to elucidate 100,000+ rows of errors in database to ensure reliability of data."],
        translate: [300, 0]
    }
]
const Experience = () => {

    return (
        <Container id="experience">
            <h1>Experience</h1>
            <hr />
            {experienceData
                .map((job, index) => (
                    <ExperienceItem key={index} job={job}/>
                ))}
        </Container>
    );
};

export default Experience;